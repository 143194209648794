//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LocalStorage } from "quasar";
export default {
  name: "IndexSale",
  props: [
    "_stores",
    "_currentStore",
    "_startDate",
    "_endDate",
    "_brandCode",
    "_seasonVal",
  ],
  data() {
    return {
      chartIns: {
        saleTrend: null,
      },
      stores: this._stores,
      currentStore: this._currentStore,
      startDate: this._startDate,
      endDate: this._endDate,
      brandCode: this._brandCode,
      seasonVal: this._seasonVal,
      apis: {},
      basicData: {},
      levelData: {},
      categoryData: [],
      categoryDataMore: [],
      localCategoryData: [],
      localCategoryDataMore: [],
      thisNumSort: [],
      thisNumSortMore: [],
      thisMoneySort: [],
      thisMoneySortMore: [],
      allNumSort: [],
      allNumSortMore: [],
      allMoneySort: [],
      allMoneySortMore: [],
      allSortBy: "allNum",
      thisSortBy: "thisNum",
      categorySaleLoading: false,
      localCategorySaleLoading: false,
      kdgrp: LocalStorage.getItem("kdgrp"),
      brandData: {
        ZBXN: "10,13,16",
        ZOLT: "10,13,16",
        ZCAM: "27",
        ZHAZ: "21",
        ZTOB: "29",
        ZLAF: "30",
      },
    };
  },
  created() {
    if (this.stores.length > 0) {
      this.dispatchApi();
    }
  },
  activated() {
    window.document.title = "门店业绩";
    if (this.drawSaleTrend && this.trendData) {
      this.drawSaleTrend(
        this.trendData.xData,
        this.trendData.zhehouData,
        this.trendData.diaopaiData,
        this.trendData.shishouData
      );
    }
  },
  methods: {
    buildSeason(data){
      if(this.seasonVal=='X'){
      
        data.push({
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "ZZJJFL_MVOO_001",
          LOW: 'A',
          HIGH: "",
          LOW_FLAG: "1",
        },{
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "ZZJJFL_MVOO_001",
          LOW: 'B',
          HIGH: "",
          LOW_FLAG: "1",
        },{
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "ZZJJFL_MVOO_001",
          LOW: 'X',
          HIGH: "",
          LOW_FLAG: "1",
        })

      }else if(this.seasonVal=='Y'){
        data.push({
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "ZZJJFL_MVOO_001",
          LOW: 'C',
          HIGH: "",
          LOW_FLAG: "1",
        },{
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "ZZJJFL_MVOO_001",
          LOW: 'D',
          HIGH: "",
          LOW_FLAG: "1",
        },{
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "ZZJJFL_MVOO_001",
          LOW: 'Y',
          HIGH: "",
          LOW_FLAG: "1",
        })
      }
      return data
    },
    updateParams(store, start, end, brand, seasonVal) {
 
      this.currentStore = store;
      this.startDate = start;
      this.endDate = end;
      this.seasonVal = seasonVal;
      if (brand) {
        this.brandCode = brand;
      }
    },
    dispatchApi() {
    

      this.getCategorySaleData();
      this.getLocalCategorySaleData();

      //  this.get_store_sale_top30()
    },

    // get_store_sale_top30() {

    //   const data = [
    //     {
    //       SIGN: "I",
    //       OPTION: "BT",
    //       PARAMETER: "Z0CALDAY_MVIM_001",
    //       LOW: '20200101',
    //       HIGH: '20201231',
    //       LOW_FLAG: "1",
    //     },
    //     {
    //       SIGN: "I",
    //       OPTION: "EQ",
    //       PARAMETER: "ZZSTORENEW_MVOO_001",
    //       LOW: "E001Z003",
    //       HIGH: "",
    //       LOW_FLAG: "1",
    //     }, {
    //       SIGN: "I",
    //       OPTION: "EQ",
    //       PARAMETER: "ZZPPG_MVOO_001",
    //       LOW: "10",
    //       HIGH: "",
    //       LOW_FLAG: "1",
    //     }
    //     // {"SIGN":"I","OPTION":"EQ","PARAMETER":"ZPP_003","LOW":"10","HIGH":"","LOW_FLAG":"1"}
    //   ];
    //   this.categorySaleLoading = true;
    //   // this.axios.post('/v1/bw/BwApi/' + this.apis['QueryReport_queryCategorySalesProportion'], data).then(res=>{
    //   this.axios
    //     .post(
    //       "/" +
    //         this.$ajaxPrefix.consumer +
    //         "/bw/BwApi/store_sale_top30",
    //       data
    //     )
    //     .then((res) => {
    //       console.log(res)

    //     });
    // },
    getCategorySaleData() {
      const that = this;
      let data = [
        {
          SIGN: "I",
          OPTION: "BT",
          PARAMETER: "Z0CALDAY_MVIM_001",
          LOW: this.startDate,
          HIGH: this.endDate,
          LOW_FLAG: "1",
        },
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0MATL_TYPE_MVOO_001",
          LOW: "ZFRT",
          HIGH: "",
          LOW_FLAG: "1",
        },

        // {"SIGN":"I","OPTION":"EQ","PARAMETER":"ZPP_003","LOW":"10","HIGH":"","LOW_FLAG":"1"}
      ];
      data=this.buildSeason(data);
      data=this.buildBrand(data);
      this.categorySaleLoading = true;
      // this.axios.post('/v1/bw/BwApi/' + this.apis['QueryReport_queryCategorySalesProportion'], data).then(res=>{
      this.axios
        .post(
          "/" +
            this.$ajaxPrefix.consumer +
            "/bw/BwApi/qywx_getCategoryProportionList",
          data
        )
        .then((res) => {
          console.log(res);

          if (res && res.data && res.data.data) {
            const temp = res.data.data;
            LocalStorage.set("rawCondition", JSON.stringify(data));
            this.categoryData = this.dataProcessing(temp);
  
          } else {
            that.categoryData = [];
            that.categoryDataMore = [];
          }
          that.categorySaleLoading = false;
        });
    },
    getLocalCategorySaleData() {
      const that = this;
      let data = [
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0CUSTOMER_MVOO_001",
          LOW: this.currentStore,
          // LOW: 'E001Z003',
          HIGH: "",
          LOW_FLAG: "1",
        },
        {
          SIGN: "I",
          OPTION: "BT",
          PARAMETER: "Z0CALDAY_MVIM_001",
          LOW: this.startDate,
          HIGH: this.endDate,
          LOW_FLAG: "1",
        },
        {
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "Z0MATL_TYPE_MVOO_001",
          LOW: "ZFRT",
          HIGH: "",
          LOW_FLAG: "1",
        }
      ];
       data=this.buildSeason(data);
       data=this.buildBrand(data);
      this.localCategorySaleLoading = true;

      this.axios
        .post(
          "/" +
            this.$ajaxPrefix.consumer +
            "/bw/BwApi/qywx_getCategoryProportionList",
          data
        )
        .then((res) => {
          if (res && res.data && res.data.data) {
            const temp = res.data.data;
            LocalStorage.set("rawLocalCondition", JSON.stringify(data));
            this.localCategoryData = this.dataProcessing(temp);
          } else {
            that.localCategoryData = [];
            that.localCategoryDataMore = [];
          }
          that.localCategorySaleLoading = false;
        });
    },

    dataProcessing(cateData) {
      let keys = Object.keys(cateData);
      let list = [];
      keys.forEach((item) => {
        list.push(cateData[item]);
      });

      list.sort((a, b) => {
        return b.paidAmount - a.paidAmount;
      });
      return list;
    },
    showCateDetail(category, type) {
     
      this.$router.push(
        "/storeanalysis/catedetail?category=" + category + "&type=" + type
      );
    },
    showMoreCategoryData() {
      this.$router.push("/storeanalysis/moredata?seg=category");
    },
    showMoreThisSort() {
      this.$router.push("/storeanalysis/moredata?seg=thisTop");
    },
    showMoreAllSort() {
      this.$router.push("/storeanalysis/moredata?seg=allTop");
    },
    buildBrand(data) {
      let brandArr = this.brandData[this.brandCode].split(",");
      for (let index = 0; index < data.length; index++) {
        if (data[index].PARAMETER == "ZZPPG_MVOO_001") {
          data.splice(index, 1);
        }
      }
      for (let i = 0; i < brandArr.length; i++) {
        const element = brandArr[i];
        data.push({
          SIGN: "I",
          OPTION: "EQ",
          PARAMETER: "ZZPPG_MVOO_001",
          LOW: element,
          HIGH: "",
          LOW_FLAG: "1",
        });
      }
      return data
    },
  },
};
